import React from 'react';
import { Link } from 'react-router';

import { useProductData } from '~/features/products/products-actions';
import { sundayStoreProductDetailsRoute } from '~/routes';

/**
 * Used to render PDP links when you only have a slug,
 * typically from "productLink" links in Sanity WYSIWYG content.
 * Sanity only knows about product slugs, but we need the category hierarchy
 * to build the URL so this component fetches the necessary data to build it.
 */
const ProductLink = ({ slug, children, ...rest }) => {
  const { product } = useProductData({ slug });

  const to = product
    ? sundayStoreProductDetailsRoute({
        category: product?.productDetails?.categories?.[0]?.category?.slug,
        slug,
      })
    : '';

  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};

export default ProductLink;
