export const recommendationsApi = (http) => {
  return {
    fetchPestPlanRecommendations: async () => {
      return http.get(`/api/pest-plan-recommendations/`);
    },
    fetchSeedRecommendations: async () => {
      return http.get(`/api/seed-recommendations/`);
    },
    fetchGardenRecommendations: async () => {
      return http.get('/api/garden-recommendations');
    },
    fetchPestInLawnRecommendations: async () => {
      return http.get('/api/yard-pest-plan-recommendations/');
    },
  };
};
