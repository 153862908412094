/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import Dropdown from '~/components/navigation/desktop/dropdown';
import SubNavContent from '~/components/navigation/sub-nav-content';
import LazyImg from '~/components/lazy-img';
import * as r from '~/routes';
import { PEST_PLAN_FLAG } from '~/feature-flags';

import navStyles from '~/components/navigation/desktop/desktop-nav.module.scss';
import styles from '~/components/navigation/mobile/nav-sub-menus.module.scss';

const SubNavMenu = ({
  selectedMenu,
  lawnSubscriber,
  isCurrentPestSubscriber,
}) => {
  const menuContent = SubNavContent[selectedMenu];

  // Send subscribers to MyPlan, send others to newLawn/newPest, or use original route
  const featuredRoute =
    (lawnSubscriber &&
      menuContent?.featured?.link?.route === r.newLawnRoute()) ||
    (isCurrentPestSubscriber &&
      menuContent?.featured?.link?.route === r.newPestRoute())
      ? r.myPlanRoute()
      : menuContent?.featured?.link?.route;

  return (
    <Dropdown
      buttonContent={menuContent?.headerText}
      buttonClassName={navStyles.link}
      buttonClassNameOpen={navStyles.activeLink}
    >
      <div
        className={cx(styles.subNavContainer, {
          [styles.shedColumns]: menuContent?.shedCustom,
        })}
      >
        {menuContent?.shedCustom && (
          <ShedCustom
            content={menuContent?.shedCustom}
            headerText={menuContent?.headerText}
            headerSubText={menuContent?.headerSubText}
          />
        )}

        <div className={styles.sectionColumn}>
          <p className={styles.bodySubHeader}>{menuContent?.section1.title}</p>
          <ul className={styles.linksList}>
            {menuContent?.section1.links.map((link) =>
              !PEST_PLAN_FLAG &&
              link.route === r.customPestPlanRoute() ? null : (
                <li key={link.heapId}>
                  <Link
                    className={styles.basicLink}
                    data-heap-id={link.heapId}
                    to={link.route}
                  >
                    {link.text}
                  </Link>
                </li>
              )
            )}
          </ul>
        </div>

        <div
          className={cx(styles.sectionColumn, {
            [styles.customColumn]: menuContent?.section2.customContent,
          })}
        >
          <p className={styles.bodySubHeader}>{menuContent?.section2.title}</p>
          <ul className={styles.linksList}>
            {menuContent?.section2.customContent?.map((item) => (
              <li key={item.text} className={styles.customItem}>
                <img
                  alt={item.altText}
                  className={styles.customItemIcon}
                  height={item.height}
                  src={item.icon}
                  width={item.width}
                />
                <p className={styles.customItemText}>{item.text}</p>
              </li>
            ))}

            {menuContent?.section2.links?.map((link) => (
              <li key={link.heapId}>
                <Link
                  className={styles.basicLink}
                  data-heap-id={link.heapId}
                  style={{ color: '#6E7370' }}
                  to={link.route}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {menuContent?.featured && (
          <div className={styles.featuredColumn}>
            <LazyImg
              alt={menuContent?.featured.image.altText}
              className={styles.featuredItemImg}
              height={menuContent?.featured.image.height}
              srcSet={menuContent?.featured.image.src}
              width={menuContent?.featured.image.width}
            />
            <Link
              className={styles.featuredItemLink}
              data-head-id={menuContent?.featured.link.heapId}
              to={featuredRoute}
            >
              {menuContent?.featured.link.text}
              <img
                alt="Green arrow right"
                className={styles.featuredItemLinkIcon}
                src="/icons/green-arrow-icon.svg"
                height="13.8"
                width="15"
              />
            </Link>
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default SubNavMenu;

const ShedCustom = ({ content, headerText, headerSubText }) => (
  <div className={styles.shedCustomColumn}>
    <LazyImg
      alt={content?.image.altText}
      className={styles.shedCustomImg}
      height={content?.image.height}
      src={content?.image.src}
      width={content?.image.width}
    />
    <div className={styles.shedCustomBody}>
      <LazyImg
        alt="shed-logo"
        className={styles.shedLogo}
        src="/icons/shed-logo.svg"
        width="70"
        height="65.86"
      />
      <p className={styles.shedHeader}>{headerText}</p>
      <p className={styles.shedSubHeader}>{headerSubText}</p>
      <Link
        className={styles.featuredItemLink}
        data-head-id={content?.link.heapId}
        to={content?.link.route}
      >
        {content?.link.text}
        <img
          alt="Green arrow right"
          className={styles.featuredItemLinkIcon}
          src="/icons/green-arrow-icon.svg"
          height="13.8"
          width="15"
        />
      </Link>
    </div>
  </div>
);
