import React from 'react';
import { Link } from 'react-router';

import ProductBadge from '~/components/products-grid/products-cell-badge';
import BlurImg from '~/components/blur-img';
import AddToCartButton from '~/components/add-to-cart-button/add-to-cart-button';
import { urlFor } from '~/sanity/images';
import { sundayStoreProductDetailsRoute } from '~/routes';
import { CHANNEL_FUNNEL, CHANNEL_NON_FUNNEL } from '~/utils/channels';
import getChannelAndPrice from '~/utils/get-channel-price';
import { centsToCurrency } from '~/utils/numbers';
import Button from '~/components/button';
import { EventType, analyticsBeacon } from '~/utils/analytics';
import { useModal } from '~/features/modals/modal-actions';
import { useReviewProductSku } from '~/hooks/use-review-product-sku';
import { useYotpo } from '~/hooks/use-yotpo';
import { ReviewStars } from '~/components/static/smart-lawn-plan/review';
import { PDP_DISPLAY_TYPE } from '~/components/sunday-store/components/product-details/product-details-page';

import styles from '~/components/sunday-store/components/product-card.module.scss';

export const BasicProductCard = ({
  product,
  channel = CHANNEL_NON_FUNNEL,
  objectID,
  queryID,
  position,
}) => {
  if (!product) {
    <SkeletonAccountProduct />;
  }

  const isLawnPlanProduct =
    product.productDetails?.pdpDisplayType ===
    PDP_DISPLAY_TYPE.LAWN_PLAN_PRODUCT;

  return (
    <div className={styles.productCardWrapper}>
      <PdpLinkWrapper
        product={product}
        objectID={objectID}
        queryID={queryID}
        position={position}
      >
        <ImageContent
          product={product}
          channel={channel}
          isLawnPlanProduct={isLawnPlanProduct}
        />
      </PdpLinkWrapper>
      <PdpLinkWrapper
        product={product}
        objectID={objectID}
        queryID={queryID}
        position={position}
      >
        <InfoContent
          product={product}
          channel={channel}
          isLawnPlanProduct={isLawnPlanProduct}
        />
      </PdpLinkWrapper>
      <div className={styles.productCardFooter}>
        <ReviewContent product={product} />
        <ShippingContent />
        <AddToCartContent
          isLawnPlanProduct={isLawnPlanProduct}
          product={product}
          channel={channel}
          queryID={queryID}
          objectID={objectID}
          position={position}
        />
      </div>
    </div>
  );
};

export const ModalProductCard = ({
  product,
  channel = CHANNEL_FUNNEL,
  objectID,
  queryID,
  position,
  children,
  ctaButton,
  showAtc,
}) => {
  if (!product) {
    <SkeletonAccountProduct />;
  }

  return (
    <div className={styles.productCardWrapper}>
      <TriggerModalWrapper
        product={product}
        channel={channel}
        objectID={objectID}
        queryID={queryID}
        position={position}
        ctaButton={ctaButton}
        showAtc={showAtc}
      >
        <ImageContent product={product} channel={channel}></ImageContent>
      </TriggerModalWrapper>
      <InfoContent
        product={product}
        channel={channel}
        triggerModal
        objectID={objectID}
        queryID={queryID}
        position={position}
        ctaButton={ctaButton}
        showAtc={showAtc}
      />
      {children}
    </div>
  );
};

// Inner components

const ProductImg = ({ productDetails }) => {
  const src = urlFor(productDetails.primaryImage)
    .height(400)
    .width(400)
    .fit('max')
    .url();

  return (
    <BlurImg
      className={styles.cardImage}
      previewSrc={productDetails.primaryImagePreview}
      src={src}
      height={400}
      width={400}
      alt={productDetails?.primaryImage?.alt ?? productDetails?.name}
    />
  );
};

const Price = ({ fullPrice, unitPrice }) => {
  const regularPrice = (
    <p className={styles.productPrice}>
      {centsToCurrency(fullPrice, { round: true })}
    </p>
  );

  const discountPrice = (
    <>
      <p className={styles.productPrice}>
        <span className={styles.salePrice}>
          {centsToCurrency(unitPrice, { round: true })}
        </span>{' '}
        <span className={styles.fullPrice}>
          {centsToCurrency(fullPrice, { round: true })}
        </span>
      </p>
    </>
  );

  if (unitPrice === fullPrice) {
    return regularPrice;
  }

  return discountPrice;
};

const SkeletonAccountProduct = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <img
        className={styles.image}
        src="https://via.placeholder.com/100/cdcbce/cdcbce"
        alt=""
      />
    </div>

    <div className="skeletonText" />
    <div className="skeletonText" />
    <br />

    <Button type="button" className="skeletonButton" disabled>
      &nbsp; &nbsp; &nbsp;
    </Button>
  </div>
);

const ImageContent = ({ product, channel, children }) => {
  const { purchaseSku, productDetails } = product || {};
  const { unitPrice, fullPrice } = getChannelAndPrice(channel, product);

  return (
    <div className={styles.imageContent}>
      <ProductBadge
        fullPrice={fullPrice}
        productDetails={productDetails}
        purchaseSku={purchaseSku}
        unitPrice={unitPrice}
      />
      <ProductImg productDetails={productDetails} />
      {children}
    </div>
  );
};

const InfoContent = ({
  product,
  channel,
  isLawnPlanProduct,
  triggerModal,
  objectID,
  queryID,
  position,
  ctaButton,
  showAtc,
}) => {
  const { purchaseSku, productDetails } = product;
  const { unitPrice, fullPrice } = getChannelAndPrice(channel, product);

  return (
    <div className={styles.productTextContainer}>
      {triggerModal ? (
        <TriggerModalWrapper
          product={product}
          channel={channel}
          objectID={objectID}
          queryID={queryID}
          position={position}
          ctaButton={ctaButton}
          showAtc={showAtc}
          showViewDetailsButton={false}
        >
          <p className={styles.productName}>{productDetails.name}</p>
        </TriggerModalWrapper>
      ) : (
        <p className={styles.productName}>{productDetails.name}</p>
      )}
      {!isLawnPlanProduct && (
        <Price
          fullPrice={fullPrice}
          unitPrice={unitPrice}
          purchaseSku={purchaseSku}
        />
      )}
    </div>
  );
};

export const ReviewContent = ({ product }) => {
  const reviewProductSku = useReviewProductSku(product?.productDetails.sku);
  const { totalReviews, averageRating } = useYotpo(reviewProductSku);

  if (!totalReviews || !averageRating) {
    return null;
  }

  return (
    <div className={styles.reviewStarsContainer}>
      <ReviewStars rating={averageRating} />
      <p className={styles.reviewCount}>({totalReviews})</p>
    </div>
  );
};

export const ShippingContent = () => (
  <div className={styles.freeShipping}>
    <img
      className={styles.shippingIcon}
      src="/icons/sunday-store/shipping-box-icon.svg"
      alt="Shipping box"
      height="24"
      width="24"
    />
    <p className={styles.shippingText}>Free Shipping!</p>
  </div>
);

export const AddToCartContent = ({
  product,
  channel,
  objectID,
  queryID,
  position,
  isLawnPlanProduct,
  onSuccessOverride,
}) => {
  const { showModal } = useModal();
  const productSlug = sundayStoreProductDetailsRoute({
    category: product?.productDetails?.categories?.[0]?.category?.slug,
    slug: product?.productDetails?.slug,
  });

  return (
    <div className={styles.addToCartButton}>
      {isLawnPlanProduct ? (
        <Button
          id="plan-exclusive-cta-card"
          to={productSlug}
          fullWidth
          size="small"
          variant="primary"
        >
          Learn more
        </Button>
      ) : (
        <AddToCartButton
          fullWidth
          channel={channel}
          product={product}
          objectID={objectID}
          queryID={queryID}
          position={position}
          variant="primary"
          location="Product List Page"
          size="small"
          onSuccess={() => {
            if (onSuccessOverride) {
              onSuccessOverride(product);
              return;
            }

            showModal('ADDED_TO_CART', {
              product,
            });
          }}
        />
      )}
    </div>
  );
};

const PdpLinkWrapper = ({ product, children, queryID, objectID, position }) => {
  const { productDetails } = product || {};
  const handleClick = () => {
    trackProductClicked(
      productDetails.name,
      productDetails.sku,
      objectID,
      queryID,
      position
    );
  };

  const productSlug = sundayStoreProductDetailsRoute({
    category: productDetails?.categories?.[0]?.category?.slug,
    slug: product?.productDetails?.slug,
  });

  return (
    <Link
      className={styles.pdpLinkWrapper}
      onClick={handleClick}
      to={productSlug}
      state={{
        queryID,
        position,
        objectID,
      }}
    >
      {children}
    </Link>
  );
};

export const TriggerModalWrapper = ({
  product,
  channel,
  children,
  queryID,
  objectID,
  position,
  ctaButton,
  showViewDetailsButton = true,
  showAtc,
  showPspCopy,
}) => {
  const { showModal } = useModal();
  const { productDetails } = product || {};

  const handleClick = (e) => {
    e.preventDefault();
    showModal('PRODUCT_DETAIL', {
      channel,
      product,
      showViewDetailsLink: false,
      ctaButton,
      showAtc,
      showPspCopy,
    });
    trackProductClicked(
      productDetails.name,
      productDetails.sku,
      objectID,
      queryID,
      position
    );
  };

  return (
    <div className={styles.modalTriggerWrapper}>
      <button className={styles.triggerModalButton} onClick={handleClick}>
        {children}
      </button>
      {showViewDetailsButton && (
        <Button
          aria-label="View Details"
          className={styles.detailsTriggerButton}
          fullWidth
          onClick={handleClick}
        >
          <img
            src="/icons/cart-add.svg"
            style={{ width: '1.6rem' }}
            alt="add to cart"
          />

          <p className={styles.detailsText}>VIEW DETAILS</p>
        </Button>
      )}
    </div>
  );
};

// Helper function

const trackProductClicked = (name, sku, objectID, queryID, position) => {
  analyticsBeacon.emit(EventType.PRODUCT_CLICKED, {
    name,
    sku,
    objectID,
    queryID,
    position,
  });
};
