export const cancellationsApi = (http) => {
  return {
    fetchCancellationReasons: async () => {
      const { cancellationReasons } = await http.get(
        '/api/users/subscriptions/choices'
      );

      const parsedReasons = cancellationReasons.map(
        ([reasonCode, reasonLabel]) => ({
          reasonCode,
          reasonLabel,
        })
      );

      return parsedReasons;
    },
    createCancellationRequest: (subscriptionUuids, userReason, notes) => {
      return http.post('/api/users/subscriptions/cancel', {
        items: subscriptionUuids.map((uuid) => ({ subscription: uuid })),
        userReason,
        notes,
      });
    },
    redeemOffer: (cancellationRequestUuid, offerUuid) => {
      return http.post('/api/users/subscriptions/retention-offers/redeem', {
        cancellationRequest: cancellationRequestUuid,
        offer: offerUuid,
      });
    },
    cancelSubscription: (
      cancellationRequestUuid,
      isDoNotRenew,
      isRefundUsingCredits
    ) => {
      return http.put('/api/users/subscriptions/confirm-cancel', {
        uuid: cancellationRequestUuid,
        confirm: true,
        keepScheduledDeliveries: isDoNotRenew,
        refundUsingCredits: isRefundUsingCredits,
      });
    },
    fetchCancellationRequest: (cancellationRequestUuid) => {
      return http.get(
        `/api/users/subscriptions/cancellation-requests/${cancellationRequestUuid}/`
      );
    },
  };
};
