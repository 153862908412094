import React from 'react';
import { NavLink } from 'react-router';
import cx from 'classnames';

import MobileNavMenu from '~/components/navigation/mobile/mobile-nav-menu';
import CartLink from '~/components/navigation/cart-link/cart-link';
import SearchWidget from '~/components/search/search-widget';
import ShedProgressBar from '~/components/the-shed/components/progress-bar';
import { homeRoute } from '~/routes';

import styles from '~/components/navigation/mobile/mobile-nav.module.scss';

const MobileNav = ({ isOpen, toggleOpen }) => {
  const isSearchPage = window.location.pathname.includes('search');
  const isShedArticlePage = window.location.pathname.includes('/shed/');

  return (
    <header className={styles.nav} data-testid="mobile-nav">
      <div
        className={styles.gradient}
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          background: isOpen ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
        }}
      />
      <div className={styles.topRow}>
        <button
          className={styles.hamburgerMenuButton}
          type="button"
          onClick={toggleOpen}
          aria-label="Open navigation menu"
        >
          <svg
            width="45"
            height="45"
            viewBox="0 0 55 55"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 12 27 C 42 27 42 27 42 27 C 42 27 42 27 42 27 C 42 27 42 27 42 27 C 42 27 42 27 42 27"
              stroke="var(--black)"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M 42 16 L 12 16"
              stroke="var(--black)"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M 12 38 L 42 38"
              stroke="var(--black)"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <NavLink className={styles.logoLink} to={homeRoute()} aria-label="Home">
          <img
            className={styles.logo}
            src="/images/logo-black.svg?v1"
            alt="Sunday Lawn & Garden Care"
            width="300"
            height="92"
          />
        </NavLink>

        <div
          className={cx(styles.searchWidgetWrapper, {
            [styles.searchVisibility]: isSearchPage,
          })}
        >
          <SearchWidget />
        </div>
        <CartLink />

        <MobileNavMenu isOpen={isOpen} toggleOpen={toggleOpen} />
      </div>
      {isShedArticlePage && <ShedProgressBar />}
    </header>
  );
};

export default MobileNav;
