import React from 'react';
import { useLocation, Navigate } from 'react-router';
import queryString from 'query-string';

import { useSession } from '~/features/session/session-actions';
import { signInRoute } from '~/routes';
import { useDocumentHead } from '~/hooks/use-document-head';

const AuthRoute = ({ children }) => {
  const { isSignedIn } = useSession();

  useDocumentHead('meta', {
    name: 'robots',
    content: 'noindex, follow',
  });

  const location = useLocation();
  const params = queryString.parse(location.search);
  const redirectQueryString = queryString.stringify({
    redirect: location.pathname + location.search,
    ...params,
  });

  if (!isSignedIn) {
    return <Navigate replace to={`${signInRoute()}?${redirectQueryString}`} />;
  } else {
    return children;
  }
};

export default AuthRoute;
