import React from 'react';
import { NavLink, useLocation } from 'react-router';
import queryString from 'query-string';
import cx from 'classnames';

import LazyImg from '~/components/lazy-img';
import Button from '~/components/button';
import { useSession } from '~/features/session/session-actions';
import {
  shedRoute,
  signInRoute,
  ingredientsRoute,
  aboutUsRoute,
  guaranteeRoute,
  termsRoute,
  homeRoute,
  privacySettingsRoute,
  careerPageRoute,
  helpCenterRoute,
  shedArticleRoute,
  reviewsRoute,
  ourMissionRoute,
  regionalSeoIndexRoute,
} from '~/routes';
import { PRO_SUPPLY_FLAG, REGIONAL_SEO_FLAG } from '~/feature-flags';

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === homeRoute();
  const { isSignedIn, signOut } = useSession();

  const redirectQueryString = queryString.stringify({
    redirect: location?.pathname + location?.search,
  });

  return (
    <footer
      className={cx('footer', {
        'footer--extra-bottom-padding': location.pathname.includes('lawn-plan'),
      })}
    >
      <div className="footer__logo-wrapper">
        <NavLink to={homeRoute()} aria-label="Home">
          <img
            className="footer__logo"
            src="/images/logo-white.svg?v1"
            alt="Sunday Lawn & Garden Care"
            width="300"
            height="92"
          />
        </NavLink>
      </div>

      <div className="container footer__inner">
        <div className="footer__link-group help">
          <p className="footer__link-title">Customer service</p>
          <NavLink
            className="footer__link"
            to={helpCenterRoute()}
            data-heap-id="footer-help"
          >
            <span className="underline">Need help?</span>
          </NavLink>
          <NavLink
            className="footer__link"
            to={reviewsRoute()}
            data-heap-id="footer-reviews"
          >
            <span className="underline">Customer reviews</span>
          </NavLink>
          <NavLink
            className="footer__link"
            to={guaranteeRoute()}
            data-heap-id="footer-guarantee"
          >
            <span className="underline">Guarantee</span>
          </NavLink>
          <NavLink
            className="footer__link"
            to={ingredientsRoute()}
            data-heap-id="footer-ingredients"
          >
            <span className="underline">Ingredients</span>
          </NavLink>
          {REGIONAL_SEO_FLAG && (
            <NavLink
              className="footer__link"
              to={regionalSeoIndexRoute()}
              data-heap-id="footer-local-lawn-care"
            >
              <span className="underline">Local guides</span>
            </NavLink>
          )}
        </div>

        <div className="footer__link-group account">
          <p className="footer__link-title">My account</p>
          {isSignedIn ? (
            <Button
              className="footer__link"
              variant="link"
              type="button"
              onClick={signOut}
              data-heap-id="footer-sign-out"
            >
              <span className="underline">Sign out</span>
            </Button>
          ) : (
            <NavLink
              className="footer__link"
              to={`${signInRoute()}?${redirectQueryString}`}
              data-heap-id="footer-sign-in"
            >
              <span className="underline">Sign in</span>
            </NavLink>
          )}
        </div>

        <div className="footer__link-group learn">
          <p className="footer__link-title">Learn more</p>
          <NavLink
            className="footer__link"
            to={aboutUsRoute()}
            data-heap-id="footer-about-us"
          >
            <span className="underline">About us</span>
          </NavLink>

          <NavLink
            className="footer__link"
            to={ourMissionRoute()}
            data-heap-id="footer-our-mission"
          >
            <span className="underline">Our mission</span>
          </NavLink>

          <NavLink
            className="footer__link"
            to={careerPageRoute()}
            data-heap-id="footer-careers"
          >
            <span className="underline">Careers</span>
          </NavLink>
          <a
            className="footer__link"
            href="https://lawn.getsunday.com/sunday-partner-affiliate/"
            data-heap-id="footer-partnerships"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="underline">Partner with us</span>
          </a>
          <a
            className="footer__link"
            href={
              PRO_SUPPLY_FLAG
                ? '/pro-supply'
                : 'https://lawn.getsunday.com/sunday-partner-pro/'
            }
            data-heap-id="footer-pros"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="underline">Pro program</span>
          </a>
        </div>

        <div className="footer__link-group social">
          <p className="footer__link-title">Social</p>
          <a
            className="footer__link footer__social-link"
            href="https://www.facebook.com/getsunday"
            aria-label="Facebook"
            data-heap-id="footer-social-facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyImg
              src="/icons/facebook-white.svg"
              alt="Facebook"
              width="25"
              height="24"
            />
            <span className="underline">Facebook</span>
          </a>
          <a
            className="footer__link footer__social-link"
            href="https://www.instagram.com/getsunday"
            aria-label="Instagram"
            data-heap-id="footer-social-instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyImg
              src="/icons/instagram-white.svg"
              alt="Instagram"
              width="25"
              height="25"
            />
            <span className="underline">Instagram</span>
          </a>
          <a
            className="footer__link footer__social-link"
            href="https://www.youtube.com/@getsunday"
            aria-label="YouTube"
            data-heap-id="footer-social-youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyImg
              src="/icons/youtube-white.svg"
              alt="YouTube"
              width="26"
              height="18"
            />
            <span className="underline">YouTube</span>
          </a>
        </div>

        <div className="footer__shed">
          <NavLink to={shedRoute()} data-heap-id="footer-shed-logo">
            <LazyImg
              className="footer__shed-img"
              src="/icons/shed-logo-yellow.svg"
              alt="The Shed - natural lawn tips and tricks"
              width="102"
              height="92"
            />
          </NavLink>
          <div className="footer__shed-details">
            <p className="footer__shed-text">
              Your guide to growing, mowing, and caring for your yard in ways
              that are better for people, pets, and planet.
            </p>
            <NavLink
              className="footer__link"
              to={shedRoute()}
              data-heap-id="footer-shed-explore"
            >
              <span className="underline">Explore The Shed</span>
            </NavLink>
          </div>

          {isHomePage && (
            <NavLink
              className="footer__ccpa-desktop"
              to={privacySettingsRoute()}
              data-heap-id="footer-privacy-settings"
            >
              <span className="underline">
                Do not sell my personal information
              </span>
            </NavLink>
          )}
        </div>
      </div>

      <div className="footer__terms">
        <a
          className="footer__link-small"
          href={window.env.PRIVACY_POLICY_URL}
          data-heap-id="footer-privacy"
        >
          <span className="underline">Privacy policy</span>
        </a>
        <span> | </span>
        <NavLink
          className="footer__link-small"
          to={termsRoute()}
          data-heap-id="footer-terms"
        >
          <span className="underline">Terms of use</span>
        </NavLink>
        <span> | </span>
        <NavLink
          className="footer__link-small"
          to={shedArticleRoute({
            category: 'sunday-way',
            slug: 'msds',
          })}
          data-heap-id="footer-msds"
        >
          <span className="underline">Safety data sheets</span>
        </NavLink>
      </div>

      <div className="footer__legal">
        <div className="footer__legal-inner">
          <div className="footer__legal-text-wrapper">
            <p className="footer__legal-text">
              All rights reserved {new Date().getFullYear()} This Land, Inc.
            </p>
            <p className="footer__legal-text">
              Built with{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="var(--tertiary-dark-color)"
                aria-label="love"
                style={{ width: '1.2rem' }}
              >
                <path d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z" />
              </svg>{' '}
              in Boulder, CO.
            </p>
          </div>

          {isHomePage && (
            <NavLink
              className="footer__ccpa"
              to={privacySettingsRoute()}
              data-heap-id="footer-privacy-settings"
            >
              <span className="underline">
                Do not sell my personal information
              </span>
            </NavLink>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
