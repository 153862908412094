import { useQuery } from 'react-query';
import { useLocation } from 'react-router';

import {
  fetchPromoBanner,
  fetchPromoBanners,
  fetchTopBanner,
} from '~/sanity/promo-banners/promo-banners-api';
import { captureException } from '~/utils/exception-tracking';

export const usePromoBanner = (pageRoute) => {
  const {
    data: allPromoBanners,
    error,
    isLoading,
  } = useQuery(['allPromoBanners'], () => fetchPromoBanners());

  const promoBanner = allPromoBanners?.find((banner) =>
    banner.pageConfig?.includes(pageRoute)
  );

  return {
    promoBanner,
    error,
    isLoading,
  };
};

export const usePromoBannerBySlug = (slug) => {
  const {
    data: promoBanner,
    error,
    isLoading,
  } = useQuery(['promoBanner', slug], () => fetchPromoBanner(slug), {
    enabled: Boolean(slug),
    onError(err) {
      captureException(new Error('Error loading promo banner'), {
        extras: {
          error: err,
          errorString: JSON.stringify(err),
        },
      });
    },
  });

  return {
    promoBanner,
    error,
    isLoading,
  };
};

export const useTopBanner = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get('topbanner');

  const {
    data: topBanner,
    error,
    isFetched: hasLoadedBanner,
    isLoading,
  } = useQuery(['topBanner', slug], () => fetchTopBanner(slug));

  return {
    topBanner,
    error,
    hasLoadedBanner,
    isLoading,
  };
};
