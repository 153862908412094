import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import { animated, useSpring } from '@react-spring/web';
import cx from 'classnames';

import Button from '~/components/button';
import { useOutsideClick } from '~/hooks/use-outside-click';
import { getTabbables, setTabbable } from '~/utils/keyboard';

import styles from '~/components/navigation/desktop/dropdown.module.scss';

/**
 * Accessible nav dropdown
 * https://www.w3.org/WAI/tutorials/menus/flyout/
 */

const Dropdown = ({
  buttonContent,
  buttonClassName,
  buttonClassNameOpen,
  children,
  onVisibilityChange,
  variant,
  ...rest
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname, location.search]);

  const styleProps = useSpring({
    from: {
      pointerEvents: 'none',
      opacity: 0,
      transform: 'translateY(-1rem)',
    },
    to: {
      pointerEvents: isOpen ? 'auto' : 'none',
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? 'translateY(0)' : 'translateY(-1rem)',
      position: variant === 'account' ? 'relative' : 'absolute',
      left: 0,
      right: 0,
      zIndex: 2,
    },
    config: {
      mass: 1,
      tension: 380,
      friction: 25,
    },
    onRest: () => {
      if (onVisibilityChange) {
        onVisibilityChange(isOpen);
      }
    },
  });

  useOutsideClick(wrapperRef, () => setIsOpen(false));

  return (
    <div
      className={styles.container}
      onMouseEnter={() => variant !== 'superCta' && setIsOpen(true)}
      onMouseLeave={() => variant !== 'superCta' && setIsOpen(false)}
      ref={wrapperRef}
    >
      <Button
        ref={buttonRef}
        className={cx(styles.button, buttonClassName, {
          [`${buttonClassNameOpen}`]: isOpen,
        })}
        type="button"
        variant="outline"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen((o) => !o);
          }
        }}
        onClick={() => setIsOpen(true)}
        aria-haspopup="true"
        aria-expanded={isOpen}
        {...rest}
      >
        {buttonContent}
      </Button>

      <animated.div style={styleProps}>
        <DropdownMenu isOpen={isOpen} setIsOpen={setIsOpen} variant={variant}>
          {children}
        </DropdownMenu>
      </animated.div>
    </div>
  );
};

export default Dropdown;

function DropdownMenu({ isOpen, setIsOpen, children, variant }) {
  const ref = useRef();

  useEffect(() => {
    const ignoreTabbableElement = ref.current.querySelector(
      '[data-ignore-dropdown-tabbable]'
    );
    const tabbables = getTabbables(ref.current).filter((el) => {
      if (!ignoreTabbableElement) {
        return true;
      }

      return !ignoreTabbableElement.contains(el);
    });

    if (isOpen) {
      setTabbable(tabbables, true);
    } else {
      setTabbable(tabbables, false);
    }

    const firstTabbable = tabbables[0];
    const lastTabbable = tabbables[tabbables.length - 1];
    const handleBlur = () => {
      /**
       * To avoid a strange bug (potentially related to react-router-dom's
       * a11y efforts w/focus management) where the <body> is the activeElement
       * immediately we do it in a setTimeout
       */
      setTimeout(() => {
        if (ref.current && !ref.current.contains(document.activeElement)) {
          setIsOpen(false);
        }
      }, 0);
    };

    if (isOpen) {
      if (firstTabbable) {
        firstTabbable.addEventListener('blur', handleBlur);
      }
      if (lastTabbable) {
        lastTabbable.addEventListener('blur', handleBlur);
      }
    }

    return () => {
      if (firstTabbable) {
        firstTabbable.removeEventListener('blur', handleBlur);
      }
      if (lastTabbable) {
        lastTabbable.removeEventListener('blur', handleBlur);
      }
    };
  }, [isOpen, setIsOpen]);

  return (
    <div
      ref={ref}
      className={cx(styles.menu, {
        [styles.accountMenu]: variant === 'account',
        [styles.superCtaMenu]: variant === 'superCta',
      })}
      onClick={() => variant === 'superCta' && setIsOpen(false)}
    >
      {variant === 'account' && (
        <svg
          className={styles.triangleIcon}
          width="19"
          height="11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.298 0 0 11h19L9.298 0Z" fill="#F9F8F8" />
        </svg>
      )}
      {children}
    </div>
  );
}
