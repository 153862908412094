import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router';

import LoadingIndicator from '~/components/loading-indicator';

import styles from '~/components/button.module.scss';

const Button = React.forwardRef(
  (
    {
      className,
      fullWidth,
      fullWidthMobile,
      size,
      variant,
      to,
      href,
      isLoading,
      ...rest
    },
    ref
  ) => {
    const classes = cx(styles.button, className, {
      [styles.block]: fullWidth,
      [styles.blockMobile]: fullWidthMobile,
      [styles.small]: size === 'small',
      [styles.tiny]: size === 'tiny',
      [styles.outline]: variant === 'outline',
      [styles.transparentGreen]: variant === 'transparentGreen',
      [styles.dark]: variant === 'dark',
      [styles.yellow]: variant === 'yellow',
      [styles.link]: variant === 'link',
    });

    if (to) {
      return <Link to={to} className={classes} ref={ref} {...rest} />;
    }

    if (href) {
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return <a href={href} className={classes} ref={ref} {...rest} />;
    }

    return (
      <button
        className={classes}
        disabled={isLoading}
        ref={ref}
        aria-label={isLoading ? 'loading' : null}
        {...rest}
      >
        {isLoading && <LoadingIndicator className={styles.loadingIndicator} />}

        <span
          className={cx({
            [styles.hidden]: isLoading,
          })}
          aria-hidden={isLoading}
        >
          {rest.children}
        </span>
      </button>
    );
  }
);

export default Button;
