/**
 * @deprecated
 * Legacy chat API that lives in digital ocean.
 */
const legacyChatApi = (http, modelEndpoint) => {
  if (!modelEndpoint) {
    throw new Error('chatApi: modelEndpoint is required');
  }

  return {
    sendMessage: ({ message, history, jsonData }) => {
      return http.post(`/api/labs/prompt-gpt-drf/${modelEndpoint}/`, {
        content: message.content,
        role: message.role,
        uuid: message.uuid,
        previousMessage: history[history.length - 1]?.uuid,
        createdAt: message.timeSent,
        customerUuid: message.userUuid,
        jsonData,
      });
    },

    assignFeedback: ({ messageUuid, isHelpful }) => {
      return http.patch(`/api/labs/prompt-gpt-drf/messages/${messageUuid}`, {
        helpful: isHelpful,
      });
    },
  };
};

export const alwaysBeClosingChatApi = (http) => legacyChatApi(http, 'always-be-closing');
export const lawnProblemChatApi = (http) => legacyChatApi(http, 'lawn-problem');

// New Sunny LLM API that lives in our cluster
export const sunnyChatApi = (http) => {
  return {
    sendMessage: ({ message }) => {
      return http.post(`/api/sunny/chat`, {
        content: message.content,
        role: message.role,
        model_name: 'always-be-closing',
        customer_uuid: message.userUuid,
        session_id: message.sessionId,
      });
    },

    assignFeedback: ({ messageUuid, isHelpful }) => {
      return http.patch(`/api/sunny/messages/${messageUuid}`, {
        helpful: isHelpful,
      });
    },
  };
}
