/**
 * There are two ways to define a feature flag
 *
 * Export a constant:
 * - Example: export const FEATURE_FLAG_FOO = true
 * - Example: export const FEATURE_FLAG_FOO = window.env.SUNDAY_ENV === 'my-generic-environment'
 *
 * Use the feature flag manager:
 * - Call .add() with a display name and a default value.
 * - Can be toggled on/off in the dev tools UI (enabled by adding "?dev-tools" to the url)
 * - Example: export const FEATURE_FLAG_PEST_FUNNEL = featureFlagManager.add('Pest Funnel', false)
 */

import { captureException } from '~/utils/exception-tracking';

export const featureFlagManager = createFeatureFlagManager();

export const PEST_PLAN_FLAG = true;
export const FEATURE_FLAG_CT_PRICES = false;
export const FEATURE_FLAG_IAS_INVENTORY = false;

// The user creation tool is currently broken with the changes that were made with cartography.
// There is a ticket to fix this. TM-452. Temporarily adding `false` to deactivate it.
export const USER_CREATION_TOOL_FLAG =
  false &&
  window.env.DEPLOY_ENV !== 'production' &&
  featureFlagManager.add(
    'USER_CREATION_TOOL_FLAG',
    ['local', 'develop'].includes(window.env.DEPLOY_ENV)
  );
export const GOOGLE_SIGN_IN_FLAG = true;
export const FACEBOOK_SIGN_IN_FLAG = true;

export const ZIPCODE_FUNNEL_FLAG = true;

// For the time between season shutdown and spring season start
export const IS_PRE_SEASON_FLAG = false;

// IS_SEASON_TRUNCATED_FLAG should be false during pre-season
// Regular lawn season = false, truncated lawn season = true
export const IS_SEASON_TRUNCATED_FLAG = false;

// Regular lawn season = true, final truncated lawn season = false
export const IS_FLEX_PAY_ENABLED_FLAG = true;

// Minimize first box plans (former FAT) default true in subsoil
// Send FF to /lawn_bundles/ endpoint to enable/disable
export const IS_MINIMIZE_FIRST_BOX_ENABLED_FLAG = true;

export const BROWN_SPOT_ID_FLAG = true;

// Regional SEO feature flag
export const REGIONAL_SEO_FLAG = true;

// Pro Supply feature flag
export const PRO_SUPPLY_FLAG = true;

function createFeatureFlagManager() {
  const featureFlags = [];

  /**
   * Define a feature flag with a default value.
   * Allows for toggling the flag in the <DevTools /> UI
   *
   * @returns {boolean}
   */
  const add = (name, defaultValue) => {
    let isEnabled = defaultValue;

    // If there's an override in session storage then use it
    const override = getSavedFeatureFlag(name);
    if (override !== null) {
      isEnabled = override;
    }

    featureFlags.push({
      name,
      isEnabled,
    });

    return isEnabled;
  };

  const getSavedFeatureFlag = (name) => {
    try {
      return JSON.parse(window.sessionStorage.getItem(`FF:${name}`));
    } catch (err) {
      captureException(err);
      return null;
    }
  };

  const saveFeatureFlag = (name, value) => {
    try {
      return window.sessionStorage.setItem(`FF:${name}`, JSON.stringify(value));
    } catch (err) {
      captureException(err);
      return null;
    }
  };

  const resetAll = () => {
    featureFlags.forEach((featureFlag) => {
      window.sessionStorage.removeItem(`FF:${featureFlag.name}`);
    });
  };

  const setFeatureFlag = (name, value) => {
    saveFeatureFlag(name, value);
  };

  return {
    add,
    featureFlags,
    resetAll,
    setFeatureFlag,
  };
}
