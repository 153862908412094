import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Index,
  useClearRefinements,
  useHits,
  useInstantSearch,
  useRefinementList,
  useSearchBox,
} from 'react-instantsearch-hooks-web';
import { Link } from 'react-router';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import Button from '~/components/button';
import { useProductData } from '~/features/products/products-actions';
import { DashHeader } from '~/components/the-shed/components';
import { urlFor } from '~/sanity/images';
import { shedArticleRoute, sundayStoreProductDetailsRoute } from '~/routes';
import LoadingIndicator from '~/components/loading-indicator';
import LazyImg from '~/components/lazy-img';
import { Price } from '~/components/sunday-store/components/product-details/product-details-page';
import { analyticsBeacon, EventType } from '~/utils/analytics';
import getChannelAndPrice from '~/utils/get-channel-price';
import { CHANNEL_NON_FUNNEL } from '~/utils/channels';
import CheckboxField from '../inputs/checkbox-field';
import { useOutsideClick } from '~/hooks/use-outside-click';
import { useReviewProductSku } from '~/hooks/use-review-product-sku';
import { useYotpo } from '~/hooks/use-yotpo';
import { ReviewStars } from '~/components/static/smart-lawn-plan/review';

import styles from '~/components/search/search-widget.module.scss';
import searchStyles from '~/components/search/search-page.module.scss';
import modalStyles from '~/components/modals/modal.module.scss';

export const algoliaAppId = window.env.ALGOLIA_APP_ID;
export const algoliaApiKey = window.env.ALGOLIA_API_KEY;

export const ALGOLIA_ARTICLES_INDEX = 'prod_sanity-articles';
export const ALGOLIA_PRODUCTS_INDEX =
  window.env.ALGOLIA_CT_PRODUCTS_INDEX || 'prod_ct_products';

export const ALGOLIA_PRODUCTS_INDEX_NAME_ASC = `${ALGOLIA_PRODUCTS_INDEX}_name_asc`;
export const ALGOLIA_PRODUCTS_INDEX_NAME_DESC = `${ALGOLIA_PRODUCTS_INDEX}_name_desc`;
export const ALGOLIA_PRODUCTS_INDEX_PRICE_ASC = `${ALGOLIA_PRODUCTS_INDEX}_price_asc`;
export const ALGOLIA_PRODUCTS_INDEX_PRICE_DESC = `${ALGOLIA_PRODUCTS_INDEX}_price_desc`;

export const SEARCH_PLACEHOLDERS = [
  'Water you looking for?',
  'It takes one to grow one...',
  'The more you grow...',
  'Looking for shrub in all the wrong places...',
  "Hello... is it green you're looking for?",
  "Now we're cooking with grass...",
];

export const SearchButton = ({ onClick = () => {}, isOpen, className }) => (
  <button
    className={cx(styles.searchButton, className)}
    onClick={onClick}
    aria-haspopup="true"
    aria-expanded={isOpen}
    aria-label="search"
    data-heap-id="nav-search-button"
  >
    <img
      alt="search icon"
      src="/icons/search-icon-blk.svg"
      height="20"
      width="20"
    />
    <p>What can we help you find today?</p>
  </button>
);

const EmptyResults = () => {
  const { products } = useProductData();

  const memoizedTrendingProducts = useMemo(() => {
    const trendingProducts =
      products?.filter((product) =>
        [
          'SSA3001', // Mosquito Deleto
          'SBA1003', // Pet Patch
          'SPA0001', // Bare Repair
          'SGA1005', // Flower and Bloom Plant Food Mix
        ].includes(product.purchaseSku.skuId)
      ) || [];

    return trendingProducts;
  }, [products]);

  return (
    <div className={styles.emptyResultsWrapper}>
      <div className={styles.contentWrapper}>
        <p className={styles.emptyResultsHeader}>No results found.</p>
        <Button variant="link" to="/help-center" className={styles.helpLink}>
          Need help?
        </Button>
      </div>
      <div className={styles.trendingHeader}>
        <DashHeader title="What's trending" />
      </div>
      {memoizedTrendingProducts.map((product) => {
        const imgSource = urlFor(product?.productDetails?.primaryImage)
          .width(160)
          .fit('crop')
          .url();

        const productSlug = sundayStoreProductDetailsRoute({
          category: product?.productDetails?.categories?.[0]?.category?.slug,
          slug: product.productDetails.slug,
        });

        return (
          <Link to={productSlug} className={styles.link} key={productSlug}>
            <div className={styles.hit}>
              <LazyImg
                src={imgSource}
                height="auto"
                width="200"
                className={styles.hitThumbnail}
                alt={product?.productDetails?.primaryImage?.alt || ''}
              />

              <div className={styles.hitContent}>
                <p className={styles.hitTitle}>{product.productDetails.name}</p>
                <p className={styles.hitTagline}>
                  {product.productDetails.tagLine}
                </p>
              </div>
            </div>
          </Link>
        );
      })}
      <Index indexName={ALGOLIA_ARTICLES_INDEX} />
      <Index indexName={ALGOLIA_PRODUCTS_INDEX} />
    </div>
  );
};

export const EmptyResultsWrapper = ({ children }) => {
  const { scopedResults } = useInstantSearch();

  const hasResults = scopedResults?.some((sr) => {
    return sr.results?.nbHits > 0 || sr.results?.userData?.length > 0;
  });

  return hasResults ? children : <EmptyResults />;
};

const SearchWidgetProduct = ({ hit, query }) => {
  const { products, isLoading, error } = useProductData();
  const imgSource = urlFor(hit?.primaryImage).width(160).fit('crop').url();

  const reviewProductSku = useReviewProductSku(hit.sku);
  const { totalReviews, averageRating } = useYotpo(reviewProductSku);

  const productSlug = sundayStoreProductDetailsRoute({
    category: hit?.categories?.[0],
    slug: hit.slug,
  });

  const matchingProduct =
    products?.find((p) => p.purchaseSku?.skuId === hit.sku) ?? {};

  const { fullPrice, unitPrice } = getChannelAndPrice(
    CHANNEL_NON_FUNNEL,
    matchingProduct
  );

  return (
    <Link
      to={productSlug}
      key={hit.objectID}
      state={{
        queryID: hit.__queryID,
        objectID: hit.objectID,
        position: hit.__position,
        query,
      }}
      className={styles.link}
      onClick={() => {
        analyticsBeacon.emit(EventType.PRODUCT_CLICKED, {
          name: hit.name,
          sku: hit.sku,
          query,
          queryID: hit.__queryID,
          objectID: hit.objectID,
          position: hit.__position,
        });
      }}
    >
      <div className={styles.hit}>
        <LazyImg
          src={imgSource}
          height="200"
          width="200"
          className={styles.hitThumbnail}
          alt={hit?.primaryImage?.alt || ''}
        />

        <div className={styles.hitContent}>
          <p className={styles.hitTitle}>{hit.name}</p>
          <p className={styles.hitTagline}>{hit.tagLine}</p>
          {!error && (
            <div className={styles.priceWrapper}>
              {matchingProduct.purchaseSku && (
                <Price
                  classname={styles.hitPrice}
                  fullPrice={fullPrice}
                  purchaseSku={matchingProduct.purchaseSku}
                  unitPrice={unitPrice}
                />
              )}
              {isLoading && (
                <LoadingIndicator className={styles.loadingIndicator} />
              )}
            </div>
          )}
          {totalReviews > 0 && (
            <div className={styles.reviewsWrapper}>
              <ReviewStars rating={averageRating} />
              <p className={styles.reviewCount}>({totalReviews})</p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

const ProductHits = () => {
  const { hits, results } = useHits();
  const query = results?.query;

  const isLoadingHits = results?.processingTimingsMS === undefined;

  // We're populating userData with redirects for now.
  // We can change the name of this variable if it stops making sense.
  const customRedirects = results?.userData ?? [];

  if (isLoadingHits) {
    return (
      <div
        style={{
          margin: '4rem auto',
          textAlign: 'center',
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  if (hits?.length === 0 && customRedirects.length === 0) {
    return (
      <p className={styles.emptyResultsHeader}>No product results found.</p>
    );
  }

  return (
    <div className={styles.hitsWrapper}>
      {hits.map((hit) => {
        return (
          <SearchWidgetProduct key={hit.objectID} hit={hit} query={query} />
        );
      })}
      {customRedirects.map((customRedirect) => (
        <Link
          to={customRedirect.redirect}
          key={customRedirect.text}
          className={styles.link}
        >
          <div className={styles.hit}>
            <LazyImg
              src={customRedirect.image || '/icons/redirect.svg'}
              height="8"
              width="8"
              className={styles.hitThumbnail}
              alt={`Redirect to ${customRedirect.text}`}
            />
            <div className={styles.hitContent}>
              <p className={styles.hitTitle}>{customRedirect.text}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export const ProductSearchResults = () => (
  <div className={styles.resultBlock}>
    <h2 className={styles.resultsHeader}>Products</h2>
    <Index indexName={ALGOLIA_PRODUCTS_INDEX}>
      <ProductHits />
    </Index>
  </div>
);

const ShedHits = () => {
  const { hits, results } = useHits();
  const query = results?.query;

  const isLoadingHits = results?.processingTimingsMS === undefined;

  if (isLoadingHits) {
    return (
      <div
        style={{
          margin: '4rem auto',
          textAlign: 'center',
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  if (!hits || hits.length === 0) {
    return <p className={styles.emptyResultsHeader}>No blog results found.</p>;
  }

  return (
    <div className={styles.hitsWrapper}>
      {hits.map((hit) => {
        const articleImg = urlFor(hit.thumbnailImage).width(160).url();
        const articleSlug = shedArticleRoute({
          category: hit.articleCategory,
          slug: hit.slug,
        });

        return (
          <Link
            key={hit.objectID}
            to={articleSlug}
            state={{
              queryID: hit.__queryID,
              position: hit.__position,
              objectID: hit.objectID,
              query,
            }}
            className={styles.link}
            onClick={() => {
              analyticsBeacon.emit(EventType.ARTICLE_CLICKED, {
                title: hit.title,
                query,
                queryID: hit.__queryID,
                objectID: hit.objectID,
                position: hit.__position,
              });
            }}
          >
            <div className={cx(styles.hit, styles.shedResult)}>
              <LazyImg
                src={articleImg}
                height="80"
                width="80"
                className={styles.hitThumbnail}
                alt={hit?.thumbnailImage?.alt || ''}
              />

              <div className={styles.hitContent}>
                <p className={styles.hitTitle}>{hit.title}</p>
                <p className={styles.hitTagline}>
                  {hit.tags
                    ?.slice(0, 3)
                    .filter(Boolean) // Some articles have empty tags
                    .map((t) => t.name)
                    .join(', ')}
                </p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export const ShedSearchResults = () => (
  <div className={styles.resultBlock}>
    <h2 className={styles.resultsHeader}>From the blog</h2>
    <Index indexName={ALGOLIA_ARTICLES_INDEX}>
      <ShedHits />
    </Index>
  </div>
);

export const SearchBox = ({
  className,
  onChange = () => {},
  onSubmit = () => {},
  autoFocus = false,
  initialValue = '',
}) => {
  const { refine, query } = useSearchBox();

  const [value, setValue] = useState(initialValue);

  // Track when the value coming from the React state changes to synchronize
  // it with InstantSearch.
  useEffect(() => {
    if (query !== value) {
      refine(value);
    }
    // We don't want to track when the InstantSearch query changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, refine]);

  // Memoize the placeholder to avoid re-rendering it on every keystroke.
  const searchPlaceholder = useMemo(() => {
    return SEARCH_PLACEHOLDERS[
      Math.floor(Math.random() * SEARCH_PLACEHOLDERS.length)
    ];
  }, []);

  return (
    <div className={className}>
      <form
        action=""
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <input
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          autoFocus={autoFocus}
          placeholder={searchPlaceholder}
          spellCheck={false}
          maxLength={512}
          type="search"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </form>
    </div>
  );
};

/**
 * @param {function} transformItems A transform function for refinements returned from Algolia (i.e. `true` -> `On sale`)
 * NOTE: `transformItems` MUST be a stable reference to a function! Do NOT define it as an inline arrow function prop. Wrap
 * in `useCallback` if defined within a component (vs. outside of any component)
 */
export const RefinementList = ({ attribute, showMore, transformItems }) => {
  const { results } = useHits();

  const { canToggleShowMore, items, refine, toggleShowMore, isShowingMore } =
    useRefinementList({
      attribute,
      showMore,
      transformItems,
    });

  return (
    <section>
      <ul>
        {items.map((item) => {
          // ensure camel case item labels are converted to sentence case
          const itemLabel = item.label
            ? item.label.replace(
                /([A-Z])/g,
                (match) => ` ${match.toLowerCase()}`
              )
            : '';

          return (
            <li
              key={item.value}
              className={searchStyles.refinementListItemLabel}
            >
              <CheckboxField
                meta={{}}
                input={{
                  name: itemLabel,
                  checked: item.isRefined,
                  onChange: () => {
                    refine(item.value);
                  },
                  onClick: () => {
                    const indexName = results?.index;
                    const queryID = results?.queryID;
                    const query = results?.query;

                    analyticsBeacon.emit(EventType.FILTER_CLICKED, {
                      filterName: itemLabel,
                      attribute,
                      indexName,
                      query,
                      queryID,
                    });
                  },
                }}
                label={itemLabel}
                value={item.value}
              />
              <span className={searchStyles.refinementListItemCount}>
                {item.count}
              </span>
            </li>
          );
        })}
      </ul>
      <Button
        className={searchStyles.refinementListShowMore}
        disabled={!canToggleShowMore}
        onClick={toggleShowMore}
        variant="link"
      >
        {isShowingMore ? 'Show less' : 'Show more'}
      </Button>
    </section>
  );
};

export const ClearRefinements = ({ variant = 'outline', fullWidth = true }) => {
  const { canRefine, refine } = useClearRefinements();

  return (
    <Button
      variant={variant}
      disabled={!canRefine}
      onClick={refine}
      fullWidth={fullWidth}
    >
      Clear all filters
    </Button>
  );
};

export const FiltersModal = ({
  isOpen,
  onClose,
  children,
  title = 'Filter',
  showClearFilters = true,
}) => {
  const wrapperRef = useRef(null);
  const { results } = useHits();

  useOutsideClick(wrapperRef, onClose);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
    }
  }, [isOpen]);

  return createPortal(
    <div
      onClose={onClose}
      className={cx(searchStyles.filtersModalWrapper, {
        [searchStyles.isOpen]: isOpen,
      })}
    >
      <section
        className={cx(modalStyles.modal, searchStyles.filtersModal)}
        ref={wrapperRef}
      >
        <button className={searchStyles.close} onClick={onClose}>
          <svg
            width="45"
            height="45"
            viewBox="0 0 55 55"
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--dark-color)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="var(--light-color)"
          >
            <path d="M 19 19 L 35 35" />
            <path d="M 35 19 L 19 35" />
          </svg>
        </button>

        <div className={searchStyles.filtersModalHeader}>
          {showClearFilters && (
            <ClearRefinements variant="link" fullWidth={false} />
          )}
        </div>

        <div className={searchStyles.filtersModalInner}>{children}</div>

        <Button fullWidth onClick={onClose}>
          View all {results?.nbHits} results
        </Button>
      </section>
    </div>,
    document.body
  );
};

/**
 *
 * Use this function to find "in-stock" products from a list of Algolia hits.
 * In an edge case, this will return a list of products if none of the hits are in stock.
 *
 * Note: this will return a { purchaseSku, productDetails } object, not an Algolia "document" object.
 * Follow-up Note: This logic could technically be accomplished within Algolia if we had a way to keep track of inventory within Algolia.
 */
export const getAvailableProducts = (hits, products) => {
  const inStockProducts = hits.reduce((acc, hit) => {
    const product = products?.find((p) => p?.purchaseSku?.skuId === hit?.sku);

    if (product?.productDetails?.inStock) {
      return [...acc, product];
    } else {
      return acc;
    }
  }, []);

  if (inStockProducts.length > 0) {
    return inStockProducts;
  }

  // In the extreme event that none of the results are in stock, return all products
  return products;
};

/**

  Algolia Webhook Queries/Projections (Updated 12-05-22)

  * You can access these through the Sanity Admin by clicking the menu in the upper right corner > Manage project > API > Webhooks

  ============== Articles ==============

  {
    _id,
    title,
    publishedAt,
    isSearchable,
    shortDescription,
    thumbnailImage,
    "thumbnailImagePreview": thumbnailImage.asset-> metadata.lqip,
    tags[] -> {
      name,
      "slug": slug.current,
    },
    "objectID": _id,
    "imageUrl": thumbnailImage.asset->url + "?w=350",
    "slug": slug.current,
    "authorName": author->name,
    "articleCategory": category.articleCategory->slug.current,
    "articleCategorySlug": category.articleCategory->slug.current,
    "articleCategoryName": category.articleCategory->name,
    "articleSubCategory": category.articleSubCategory->slug.current,
    "articleSubCategorySlug": category.articleSubCategory->slug.current,
    "articleSubCategoryName": category.articleSubCategory->name,
   }
*/
