import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * Scroll to the element with an id matching the url hash.
 * This is a quick hack to get standard browser functionality to work...
 */
export const useScrollToId = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      return;
    }

    try {
      const el = document.querySelector(location.hash);

      if (!el) {
        return;
      }

      el.scrollIntoView();
    } catch (err) {}
  }, [location.hash]);
};
