import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Configure,
  InstantSearch,
  useHits,
} from 'react-instantsearch-hooks-web';
import { animated, config, useTransition } from '@react-spring/web';
import algoliasearch from 'algoliasearch/lite';

import Button from '~/components/button';
import {
  ALGOLIA_PRODUCTS_INDEX,
  SearchBox,
  EmptyResultsWrapper,
  ProductSearchResults,
  SearchButton,
  ShedSearchResults,
  algoliaAppId,
  algoliaApiKey,
} from '~/components/search/shared';
import { useOutsideClick } from '~/hooks/use-outside-click';
import {
  hideGladlyChat,
  showGladlyChat,
} from '~/utils/analytics/plugins/gladly';
import LoadingIndicator from '~/components/loading-indicator';
import { proSupplyProductFilter } from '~/features/products/products';

import styles from '~/components/search/search-widget.module.scss';

const algoliaSearchClient = algoliasearch(algoliaAppId, algoliaApiKey);

const SearchWidget = () => {
  const [showSearch, setShowSearch] = useState(false);
  const wrapperRef = useRef(null);

  // Hide search results on route change
  const location = useLocation();
  useEffect(() => {
    if (showSearch) hideSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Hide search results on dismount
  useEffect(() => {
    return () => {
      hideSearchResults();
    };
  }, []);

  // Hide search results when Escape key is pressed,
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        hideSearchResults();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const showSearchResults = () => {
    setShowSearch(true);
    hideGladlyChat();
    document?.body?.classList?.add('noScroll');
  };

  const hideSearchResults = () => {
    setShowSearch(false);
    showGladlyChat();
    document?.body?.classList?.remove('noScroll');
  };

  useOutsideClick(wrapperRef, hideSearchResults);

  const transitions = useTransition(showSearch, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  return (
    <>
      <SearchButton
        className={styles.mobile}
        isOpen={showSearch}
        onClick={() => {
          if (showSearch) {
            // Close it if it's open
            hideSearchResults();
          } else {
            // Open & focus if it's closed
            showSearchResults();
          }
        }}
      />

      {transitions(
        (props, item) =>
          item && (
            <animated.div style={props} className={styles.searchOverlay}>
              <InstantSearch
                searchClient={algoliaSearchClient}
                indexName={ALGOLIA_PRODUCTS_INDEX}
              >
                <Configure
                  hitsPerPage={4}
                  clickAnalytics={true}
                  ruleContexts={['search_results']}
                  filters={proSupplyProductFilter}
                />

                <SearchWidgetContainer
                  hideSearchResults={hideSearchResults}
                  showSearch={showSearch}
                  ref={wrapperRef}
                />
              </InstantSearch>
            </animated.div>
          )
      )}
    </>
  );
};

const SearchResults = ({ navigate, query }) => {
  const { results } = useHits();
  const location = useLocation();
  const isShedPage = location.pathname.includes('/shed');

  const isLoadingHits = results?.processingTimingsMS === undefined;

  return (
    <div className={styles.mobileSearchResults}>
      {isLoadingHits ? (
        <div
          style={{
            margin: '1.6rem auto 3.2rem',
            textAlign: 'center',
          }}
        >
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <EmptyResultsWrapper>
            <div className={styles.resultsContainer}>
              {isShedPage ? (
                <>
                  <ShedSearchResults />
                  <ProductSearchResults />
                </>
              ) : (
                <>
                  <ProductSearchResults />
                  <ShedSearchResults />
                </>
              )}
            </div>
          </EmptyResultsWrapper>
          <div className={styles.viewMoreResults}>
            <Button
              variant="link"
              onClick={() => {
                const parsedQuery = query?.replace(/\s/g, '+');
                const navigateTo = parsedQuery
                  ? `/search?q=${parsedQuery}`
                  : '/search';

                navigate(navigateTo);
              }}
            >
              View more results
            </Button>
            <Button variant="link" to="/help-center">
              Need help?
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const SearchWidgetContainer = React.forwardRef(
  ({ hideSearchResults, showSearch }, wrapperRef) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');

    return (
      <div className={styles.searchWidgetWrapper}>
        <button className={styles.close} onClick={hideSearchResults}>
          <svg
            width="45"
            height="45"
            viewBox="0 0 55 55"
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--dark-color)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="var(--light-color)"
          >
            <path d="M 19 19 L 35 35" />
            <path d="M 35 19 L 19 35" />
          </svg>
        </button>

        <div ref={wrapperRef} className={styles.searchWidgetWrapperInner}>
          <SearchBox
            autoFocus={showSearch}
            onChange={(val) => {
              setSearchValue(val);
            }}
            className={styles.mobileSearchBox}
            onSubmit={() => {
              const parsedQuery = searchValue.replace(/\s/g, '+');
              const navigateTo = parsedQuery
                ? `/search?q=${parsedQuery}`
                : '/search';

              navigate(navigateTo);
            }}
          />
          <SearchResults navigate={navigate} query={searchValue} />
        </div>
      </div>
    );
  }
);

export default SearchWidget;
