import React from 'react';
import recommend from '@algolia/recommend';
import { RelatedProducts as AlgoliaRelatedProducts } from '@algolia/recommend-react';

import Carousel from '~/components/carousels/carousel';
import LazyLoad from '~/components/lazy-load';
import { ALGOLIA_PRODUCTS_INDEX } from '~/components/search/shared';
import {
  AddToCartContent,
  BasicProductCard,
  ModalProductCard,
  ReviewContent,
  ShippingContent,
} from '~/components/sunday-store/components/product-card';
import SundayRelatedProducts from '~/components/sunday-store/components/product-details/sunday-related-products';
import LoadingIndicator from '~/components/loading-indicator';
import { useProductData } from '~/features/products/products-actions';
import { ProductCategories } from '~/features/products/products';
import { IS_PRODUCTION } from '~/utils/environment';

import styles from '~/components/sunday-store/components/product-details/related-products.module.scss';

const algoliaRecommendClient = recommend(
  window.env.ALGOLIA_APP_ID,
  window.env.ALGOLIA_API_KEY
);

export const hideProProducts = (items) => {
  return items?.filter(
    (item) =>
      !item.categories.some(
        (category) => category === ProductCategories.PRO_SUPPLY.slug
      )
  );
};

const RelatedProducts = ({
  currentObjectID,
  productDetails,
  productsByCategory,
}) => {
  return (
    <section className={styles.relatedProducts}>
      <LazyLoad height={240}>
        {IS_PRODUCTION && currentObjectID !== '' ? (
          <AlgoliaRelatedProducts
            recommendClient={algoliaRecommendClient}
            indexName={ALGOLIA_PRODUCTS_INDEX}
            objectIDs={[currentObjectID]}
            itemComponent={RecommendedProduct}
            view={RecommendedProductsCarousel}
            headerComponent={HeaderComponent}
            maxRecommendations={8}
            transformItems={hideProProducts}
            fallbackComponent={() => (
              <SundayRelatedProducts
                productDetails={productDetails}
                productsByCategory={productsByCategory}
              />
            )}
          />
        ) : (
          <SundayRelatedProducts
            productDetails={productDetails}
            productsByCategory={productsByCategory}
          />
        )}
      </LazyLoad>
    </section>
  );
};

export default RelatedProducts;

export const HeaderComponent = () => {
  return <h2 className={styles.relatedProductsHeading}>Related Products</h2>;
};

export const RecommendedProductsCarousel = ({
  itemComponent: ItemComponent,
  itemComponentProps = {},
  items,
  maxItems,
}) => {
  const { error, isLoading: isLoadingProduct, products } = useProductData();

  if (error) {
    return;
  }

  const productsForCarousel = items
    ?.map((item) =>
      products?.find(
        (p) =>
          p.productDetails.sku === item.sku ||
          p.productDetails.sku === item.productDetails?.sku
      )
    )
    .filter(Boolean);

  const productsToRender = maxItems
    ? productsForCarousel.slice(0, maxItems)
    : productsForCarousel;

  return (
    <>
      {isLoadingProduct ? (
        <div className={styles.loadingWrapper}>
          <LoadingIndicator />
        </div>
      ) : (
        <Carousel childrenPerScroll={3} buttonClassName={styles.carouselButton}>
          {productsToRender?.map((product) => (
            <ItemComponent
              product={product}
              key={product.productDetails.slug}
              {...itemComponentProps}
            />
          ))}
        </Carousel>
      )}
    </>
  );
};

export const RecommendedProduct = ({
  product,
  triggerModal,
  onSuccessOverride,
}) => {
  if (!product) {
    return null;
  }

  return (
    <div className={styles.carouselProduct}>
      {triggerModal ? (
        <ModalProductCard
          key={product?.productDetails?.id}
          product={product}
          objectID={product?.productDetails?.id}
        >
          <div className={styles.contentWrapper}>
            <ReviewContent product={product} />
            <ShippingContent />
            <AddToCartContent
              product={product}
              onSuccessOverride={onSuccessOverride}
            />
          </div>
        </ModalProductCard>
      ) : (
        <BasicProductCard
          key={product?.productDetails?.id}
          product={product}
          objectID={product?.productDetails?.id}
        />
      )}
    </div>
  );
};
