import React from 'react';
import { Link } from 'react-router';

import AddToCartButton from '~/components/add-to-cart-button/add-to-cart-button';
import { sundayStoreProductDetailsRoute } from '~/routes';
import { urlFor } from '~/sanity/images';
import { CHANNEL_NON_FUNNEL } from '~/utils/channels';
import getChannelAndPrice from '~/utils/get-channel-price';
import { centsToCurrency } from '~/utils/numbers';
import { analyticsBeacon } from '~/utils/analytics';
import { EventType } from '~/utils/analytics/events';

import styles from '~/components/sunday-store/components/product-details/product-details-page.module.scss';

export const RecommendedProductsList = ({
  products,
  title = 'Expert-approved essentials',
  onAdd,
  onLinkClick,
}) => {
  return (
    <>
      <p className={styles.recommendedProductsListTitle}>{title}</p>
      <ul className={styles.recommendedProductsList}>
        {products.map((product) => (
          <RecommendedProductItem
            product={product}
            key={product.purchaseSku.skuId}
            onAdd={onAdd}
            onLinkClick={onLinkClick}
          />
        ))}
      </ul>
    </>
  );
};

const RecommendedProductItem = ({
  product,
  onAdd = () => {},
  onLinkClick = () => {},
}) => {
  const { productDetails } = product;
  const { unitPrice } = getChannelAndPrice(CHANNEL_NON_FUNNEL, product);

  const onSuccess = () => {
    onAdd(product);
  };

  const handleLinkClick = () => {
    analyticsBeacon.emit(EventType.PRODUCT_CLICKED, {
      name: productDetails?.name,
      sku: product.purchaseSku?.skuId,
      objectID: productDetails?.id,
    });
    onLinkClick();
  };

  return (
    <li className={styles.recommendedProductItem}>
      {productDetails?.primaryImage && (
        <Link
          to={sundayStoreProductDetailsRoute({
            category: productDetails?.categories?.[0]?.category?.slug,
            slug: productDetails?.slug,
          })}
          onClick={handleLinkClick}
        >
          <img
            className={styles.recommendedProductImage}
            src={urlFor(productDetails?.primaryImage).width(112)}
            alt={productDetails?.primaryImage?.alt || productDetails?.name}
            height="112"
            width="112"
          />
        </Link>
      )}
      <Link
        to={sundayStoreProductDetailsRoute({
          category: productDetails?.categories?.[0]?.category?.slug,
          slug: productDetails?.slug,
        })}
        onClick={handleLinkClick}
        className={styles.title}
      >
        <p>{productDetails?.name}</p>
      </Link>
      <p className={styles.price}>{centsToCurrency(unitPrice)}</p>
      <AddToCartButton
        channel={CHANNEL_NON_FUNNEL}
        product={product}
        variant="outline"
        location="Recommended Products List"
        fullWidth
        onSuccess={onSuccess}
      />
    </li>
  );
};

export default RecommendedProductsList;
