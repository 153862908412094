import { PestPlanTypes } from '~/components/pest/bundles/pest-plan-content';

export const getPestPlanType = (sku) => {
  if (!sku) {
    return null;
  }

  const totalHomeSKUs = ['BBS4007', 'BBS4008', 'BBS4009'];
  const tickSKUs = ['BBS4010', 'BBS4011', 'BBS4012'];
  const mosquitoSKUs = ['SBA3185', 'SBA3186', 'SBA3187'];

  if (totalHomeSKUs.includes(sku)) {
    return PestPlanTypes.TOTAL_HOME;
  } else if (tickSKUs.includes(sku)) {
    return PestPlanTypes.TICK;
  } else if (mosquitoSKUs.includes(sku)) {
    return PestPlanTypes.MOSQUITO;
  }

  return null;
};
