import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import LazyImg from '~/components/lazy-img';
import { urlFor } from '~/sanity/images';
import { shedArticleRoute } from '~/routes';
import { toLongFullDateString } from '~/utils/dates';

import styles from '~/components/the-shed/components/simple-recommended-articles.module.scss';

const SimpleRecommendedArticles = ({ recommendedReading }) => {
  return (
    <section>
      <p className={styles.heading}>RECOMMENDED READING</p>
      <div className={styles.articlesWrapper}>
        {recommendedReading.slice(0, 4).map((article) => (
          <SimpleRecommendedArticle article={article} key={article.title} />
        ))}
      </div>
    </section>
  );
};

export { SimpleRecommendedArticles };

const SimpleRecommendedArticle = ({ article }) => {
  const { category, thumbnailImage, title, publishedAt, slug, updatedAt } =
    article;

  const thumbnailUrl = urlFor(thumbnailImage).size(96, 96).fit('max').url();

  const articleHasUpdated =
    new Date(updatedAt).getTime() > new Date(publishedAt).getTime();

  const articleDate = articleHasUpdated
    ? toLongFullDateString(updatedAt)
    : toLongFullDateString(publishedAt);

  return (
    <Link
      className={cx(styles.articleLink)}
      to={shedArticleRoute({
        category: category.articleCategory.slug,
        slug,
      })}
      aria-label={title}
    >
      <div className={styles.thumbnailWrapper}>
        <LazyImg
          alt={thumbnailImage.alt || ''}
          className={styles.thumbnailImg}
          src={thumbnailUrl}
          height="96"
          width="96"
        />
      </div>

      <div className={styles.detailsContainer}>
        <p className={styles.title}>{title}</p>
        <p className={styles.date}>{articleDate}</p>
      </div>
    </Link>
  );
};

export { SimpleRecommendedArticle };
