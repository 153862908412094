import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import { customLawnPlanRoute } from '~/routes';

import styles from '~/components/the-shed/components/sunday-guarantee.module.scss';
import buttonStyles from '~/components/button.module.scss';

const SundayGuarantee = () => {
  return (
    <section className={styles.guaranteeSection}>
      <div className={styles.guaranteeSectionInner}>
        <div className={styles.guaranteeImageWrapper}>
          <div className={styles.guaranteeImageWrapperInner}>
            <img
              height="360"
              width="360"
              className={styles.guaranteeImage}
              srcSet="/images/smart-lawn-plan/Woman-Overseeding-Green-Sunday-Lawn-724w.jpg 724w, /images/smart-lawn-plan/Woman-Overseeding-Green-Sunday-Lawn-1400w.jpg 1400w"
              sizes="(max-width: 767px) 362px, 700px"
              src="/images/smart-lawn-plan/Woman-Overseeding-Green-Sunday-Lawn-1400w.jpg"
              alt="Woman spreading Sunday seeds"
            />
            <img
              height="114"
              width="112"
              className={styles.guaranteeIcon}
              src="/icons/sunday-guarantee-badge-green.svg"
              alt="sunday-guarantee-badge-green"
            />
          </div>
        </div>

        <div className={styles.guaranteeTextBlock}>
          <p className={styles.guaranteeTitle}>
            Grow a better lawn, guaranteed
          </p>

          <p className={cx(styles.guaranteeText, styles.mobile)}>
            We’re committed to making sure Sunday works for you and your lawn!{' '}
            <Link
              className={cx(styles.startToday, styles.mobile)}
              to={customLawnPlanRoute()}
            >
              Start today!
            </Link>
          </p>

          <p className={cx(styles.guaranteeText, styles.desktop)}>
            We’re committed to making sure Sunday works for you and your lawn,
            so our Yard Advisors are here to help with unlimited one-on-one
            support. You can’t mess it up!
          </p>

          <Link
            className={cx(
              buttonStyles.button,
              styles.startToday,
              styles.desktop
            )}
            to={customLawnPlanRoute()}
          >
            Start today!
          </Link>
        </div>
      </div>
    </section>
  );
};

export { SundayGuarantee };
