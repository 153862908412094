import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import { shedArticleRoute } from '~/routes';
import {
  ArticleBlock,
  ArticleBlockCategory,
  ArticleBlockImage,
  ArticleBlockSummary,
  ArticleBlockTitle,
  AuthorBlock,
} from '~/components/the-shed/components';

import styles from '~/components/the-shed/components/fancy-featured-article.module.scss';

const FancyFeaturedArticle = ({ article = {} }) => {
  return (
    <>
      <MobileVariant article={article} />
      <DesktopVariant article={article} />
    </>
  );
};

const DesktopVariant = ({ article = {} }) => {
  const {
    title,
    shortDescription,
    category,
    slug,
    thumbnailImage,
    thumbnailImagePreview,
    author,
    publishedAt,
    updatedAt,
  } = article;

  const articleRoute = shedArticleRoute({
    category: category?.articleCategory?.slug,
    slug,
  });

  return (
    <div
      className={cx(styles.desktopOnly, styles.fancyFeaturedArticleContainer)}
    >
      <ArticleBlock className={styles.desktopArticleBlock}>
        <div>
          <div className={styles.greenHighlight}>
            <ArticleBlockCategory
              category={category}
              className={styles.articleBlockCategory}
            />
            <ArticleBlockTitle
              title={title}
              to={articleRoute}
              className={styles.articleBlockTitle}
            />
            <AuthorBlock
              author={author}
              variant="featured-article"
              publishedAt={publishedAt}
              updatedAt={updatedAt}
              className={styles.authorBlock}
            />
          </div>
          <ArticleBlockSummary
            summary={shortDescription}
            className={styles.articleBlockSummary}
          />
          <Link to={articleRoute} className={styles.link}>
            <p className={styles.linkText}>Read this article</p>
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="#007290" />
            </svg>
          </Link>
        </div>
        <div className={styles.relativeContainer}>
          <ArticleBlockImage
            image={thumbnailImage}
            imagePreview={thumbnailImagePreview}
            to={articleRoute}
            className={styles.articleBlockImage}
          />
        </div>
      </ArticleBlock>
    </div>
  );
};

const MobileVariant = ({ article = {} }) => {
  const {
    title,
    shortDescription,
    category,
    slug,
    thumbnailImage,
    author,
    publishedAt,
    updatedAt,
  } = article;

  const articleRoute = shedArticleRoute({
    category: category?.articleCategory?.slug,
    slug,
  });
  return (
    <div
      className={cx(styles.mobileOnly, styles.fancyFeaturedArticleContainer)}
    >
      <ArticleBlock>
        <div className={styles.relativeContainer}>
          <div className={styles.articleHeader}>
            <div className={styles.verticalText}>
              <AuthorBlock
                author={author}
                variant="horizontal-simple"
                publishedAt={publishedAt}
                updatedAt={updatedAt}
                className={styles.authorBlock}
              />
              <ArticleBlockCategory
                category={category}
                className={styles.articleBlockCategory}
              />
            </div>
            <ArticleBlockImage
              image={thumbnailImage}
              to={articleRoute}
              className={styles.articleBlockImage}
            />
          </div>
          <Link to={articleRoute} className={styles.link}>
            <p className={styles.linkText}>Read this article</p>
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="#007290" />
            </svg>
          </Link>
        </div>
        <ArticleBlockTitle
          title={title}
          to={articleRoute}
          className={styles.articleBlockTitle}
        />
        <div className={styles.articleSummaryWrapper}>
          <ArticleBlockSummary
            summary={shortDescription}
            className={styles.articleBlockSummary}
          />
        </div>
      </ArticleBlock>
    </div>
  );
};

export { FancyFeaturedArticle };
