import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import LazyImg from '~/components/lazy-img';
import { urlFor } from '~/sanity/images';
import { shedArticleRoute } from '~/routes';
import {
  ArticleBlockCategory,
  ArticleBlockSummary,
  ArticleBlockTitle,
  AuthorBlock,
} from '~/components/the-shed/components';

import styles from '~/components/the-shed/components/recommended-reading.module.scss';

const RecommendedArticle = ({ article, index }) => {
  const {
    category,
    thumbnailImage,
    title,
    author,
    publishedAt,
    slug,
    shortDescription,
    updatedAt,
  } = article;

  const desktopSource = urlFor(thumbnailImage)
    .height(377)
    .width(590)
    .fit('max');

  const mobileSource = urlFor(thumbnailImage).height(96).width(96).fit('max');

  let classname = '';
  if (index === 0) {
    classname = 'featured1';
  } else if (index === 1) {
    classname = 'featured2';
  } else if (index > 1) {
    classname = `article${index + 1}`;
  }

  return (
    <div className={cx(styles.articleLink, styles[classname])}>
      <Link
        className={styles.thumbnailWrapper}
        to={shedArticleRoute({
          category: category.articleCategory.slug,
          slug,
        })}
        aria-label={title}
      >
        <LazyImg
          className={styles.thumbnailImage}
          srcSet={`
          ${mobileSource.url()} 96w,
          ${desktopSource.url()}
        `}
          sizes="(max-width: 768px) 96px, 590px"
          alt={thumbnailImage.alt || ''}
          width="590"
          height="382"
        />
      </Link>

      <div className={styles.detailsContainer}>
        <ArticleBlockCategory
          category={category}
          className={styles.articleBlockCategory}
        />
        <ArticleBlockTitle
          className={styles.articleBlockTitle}
          title={title}
          to={shedArticleRoute({
            category: category.articleCategory.slug,
            slug,
          })}
        />
        <AuthorBlock
          author={author}
          className={styles.authorBlock}
          publishedAt={publishedAt}
          updatedAt={updatedAt}
          variant="horizontal-simple"
        />
        <ArticleBlockSummary
          className={styles.articleBlockSummary}
          summary={shortDescription}
        />
      </div>
    </div>
  );
};

export { RecommendedArticle };
