import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import cx from 'classnames';

import { useGoogleMaps } from '~/hooks/use-google-maps';
import { debounce } from '~/utils/functions';
import { placeToAddress } from '~/features/addresses/address';
import { useId } from '~/hooks/use-id';
import {
  AutocompleteTypes,
  clearAutocompleteData,
  retrieveAutocompleteData,
  storeAutocompleteData,
} from '~/components/inputs/autocomplete-address-input/autocomplete-storage';

import inputStyles from '~/components/inputs/inputs.module.scss';
import styles from '~/components/inputs/autocomplete-address-input/autocomplete-address-input.module.scss';

const GoogleAutocomplete = ({
  type = AutocompleteTypes.FULL_ADDRESS,
  label,
  id,
  onInputChange,
  onSelect, // accepts two params: `address` and `isFromStorage` that is `true` when the address is set from browser storage (and not through the dropdown)
  error,
  submitFailed,
  labelClassname,
  inputClassname,
  containerClassname,
  zipRef,
  initialValue,
  useStorage = true,
  ...rest
}) => {
  const [google, googleError] = useGoogleMaps();
  const idNum = useId();
  const elementId = id || idNum;

  const autocomplete = useRef();
  const autocompleteToken = useRef();
  const places = useRef();
  const [loading, setLoading] = useState(false);
  const [addressText, setAddressText] = useState(initialValue || '');
  const [predictions, setPredictions] = useState([]);
  const activePrediction = useMemo(
    () => predictions.find((p) => p.active),
    [predictions]
  );

  useEffect(() => {
    if (typeof initialValue === 'string') {
      setAddressText(initialValue);
    }
  }, [initialValue]);

  const hasError = (Boolean(error) && submitFailed) || googleError;
  const showPredictions = Boolean(google) && predictions.length > 0;
  const activePredictionId = activePrediction
    ? activePrediction.placeId
    : undefined;

  useEffect(() => {
    if (google?.maps?.places) {
      autocomplete.current = new google.maps.places.AutocompleteService();
      autocompleteToken.current =
        new google.maps.places.AutocompleteSessionToken();
      places.current = new google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
  }, [google]);

  // Pre-fill a previous address selection if it exists
  const didInitFromStorage = useRef(false);
  useEffect(() => {
    /**
     Don't load from storage if:
     1. We've already loaded from storage
     2. If something is loading on the page (controlled by consumer)
     3. If there is an `initialValue` passed in as a prop (controlled by consumer)
    */
    if (!useStorage || didInitFromStorage.current) {
      return;
    }

    const { address, displayText } = retrieveAutocompleteData(type);
    if (address && displayText) {
      setAddressText(displayText);
      onSelect(address, true);
    }

    didInitFromStorage.current = true;
  }, [initialValue, onSelect, type, useStorage]);

  const handleChange = (e) => {
    const value = e.target.value;

    onInputChange && onInputChange(value);
    setAddressText(value);
    fetchPredictions(value);

    // Clear any previously selected addresses
    if (value === '') {
      onSelect(null);
      clearAutocompleteData(type);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPredictions = useCallback(
    debounce((value) => {
      if (!value) {
        setPredictions([]);
        return;
      }

      autocomplete?.current?.getPlacePredictions(
        {
          input: value,
          sessionToken: autocompleteToken.current,
          types:
            type === AutocompleteTypes.FULL_ADDRESS
              ? ['address']
              : ['postal_code'],
          componentRestrictions: {
            country: 'us',
          },
        },
        (predictions) => {
          setPredictions(parsePredictions(predictions));
        }
      );
    }, 300),
    [google]
  );

  const selectPrediction = (prediction) => {
    if (!prediction || loading) {
      return;
    }

    setLoading(true);

    places.current.getDetails(
      {
        placeId: prediction.placeId,
        sessionToken: autocompleteToken.current,
        fields: ['place_id', 'address_components', 'geometry'],
      },
      (place) => {
        autocompleteToken.current =
          new google.maps.places.AutocompleteSessionToken();

        const address = placeToAddress(place, type);
        const displayText = prediction.description;

        setAddressText(displayText);
        setLoading(false);
        setPredictions([]);
        onSelect(address);

        // Save the selection to localStorage so the next
        // time we load the input we can pre-fill it
        storeAutocompleteData({
          type,
          address,
          displayText,
        });
      }
    );
  };

  const setActiveAtIndex = (index) => {
    setPredictions((prevPredictions) => {
      return prevPredictions.map((p, idx) => {
        return {
          ...p,
          active: index === idx,
        };
      });
    });
  };

  const onArrowDownKeyDown = () => {
    if (
      !activePrediction ||
      activePrediction.index === predictions.length - 1
    ) {
      setActiveAtIndex(0);
    } else {
      setActiveAtIndex(activePrediction.index + 1);
    }
  };

  const onArrowUpKeyDown = () => {
    if (!activePrediction || activePrediction.index === 0) {
      setActiveAtIndex(predictions.length - 1);
    } else {
      setActiveAtIndex(activePrediction.index - 1);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        if (predictions.length > 0) {
          e.preventDefault();
          selectPrediction(activePrediction);
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        onArrowDownKeyDown();
        break;
      case 'ArrowUp':
        e.preventDefault();
        onArrowUpKeyDown();
        break;
      default:
        return;
    }
  };

  const handleBlur = () => {
    selectPrediction(activePrediction);
  };

  return (
    <>
      {label && (
        <label
          className={cx(inputStyles.label, labelClassname, {
            [inputStyles.labelError]: hasError,
          })}
          htmlFor={`autocomplete-input-${elementId}`}
        >
          {label}
        </label>
      )}

      <div className={cx(styles.container, containerClassname)}>
        <div
          role="combobox"
          aria-expanded={showPredictions}
          aria-owns={`autocomplete-listbox-${elementId}`}
          aria-controls={`autocomplete-listbox-${elementId}`}
          aria-haspopup="listbox"
          id={`autocomplete-combobox-${elementId}`}
        >
          <input
            type="search"
            id={`autocomplete-input-${elementId}`}
            className={cx(inputStyles.input, inputClassname, {
              [inputStyles.inputError]: hasError,
            })}
            value={addressText}
            autoComplete="off"
            disabled={loading}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            aria-autocomplete="list"
            aria-controls={`autocomplete-listbox-${elementId}`}
            aria-activedescendant={activePredictionId}
            aria-label={
              type === AutocompleteTypes.FULL_ADDRESS
                ? 'home address'
                : 'zip code'
            }
            data-heap-id={
              type === AutocompleteTypes.FULL_ADDRESS
                ? 'address-autocomplete-input'
                : 'zip-code-autocomplete-input'
            }
            ref={zipRef}
            {...rest}
          />
          {hasError && (
            <p className={inputStyles.errorMsg} role="alert">
              {googleError
                ? 'There was an error loading our address locator. Please reload the page to try again.'
                : error}
            </p>
          )}
        </div>

        <ul
          role="listbox"
          id={`autocomplete-listbox-${idNum}`}
          aria-label="address autocomplete choices"
          className={styles.predictions}
        >
          {predictions.map((p) => (
            <li
              key={p.placeId}
              id={p.placeId}
              className={cx(styles.prediction, {
                [`${styles.active}`]: p.active,
              })}
              // We use onMouseDown instead of onClick to take priority over blur events
              onMouseDown={() => selectPrediction(p)}
            >
              {p.description}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default GoogleAutocomplete;

export function parsePredictions(predictions) {
  if (!predictions) {
    return [];
  }

  return predictions.map((p, idx) => {
    return {
      placeId: p.place_id,
      description: p.description,
      index: idx,
      active: idx === 0,
    };
  });
}
