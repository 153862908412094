import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import * as vwoSdk from 'vwo-node-sdk';
import Cookies from 'js-cookie';

import { captureException } from '~/utils/exception-tracking';
import { EventType, analyticsBeacon } from '~/utils/analytics';

const vwoAtom = atom(null);

const isLocalDev = window.env.DEPLOY_ENV === 'local';
const isNotProd = window.env.DEPLOY_ENV !== 'production';
export const VWO_STATUS = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  FETCHING: 'fetching',
  IDLE: 'idle',
  INITIALIZED: 'initialized',
  INITIALIZING: 'initializing',
};

export const useVwoExperiment = (
  campaignKey,
  { activateOnLoad = true } = {}
) => {
  // In any env except production, allow a query param to override the variant
  const urlParams = new URLSearchParams(window.location.search);
  const [enableManualActivation, setEnableManualActivation] = useState(false);
  const [status, setStatus] = useState(VWO_STATUS.IDLE);

  const [savedVwoClient, setSavedVwoClient] = useAtom(vwoAtom);
  const [variant, setVariant] = useState(null);

  let overrideVariant;
  if (isNotProd) {
    overrideVariant = urlParams.get('variant');
  }

  useEffect(() => {
    if (overrideVariant && isNotProd) {
      Cookies.set('vwo_override', overrideVariant);
    }
  }, [overrideVariant]);

  const vwoUuid = Cookies.get('_vwo_uuid');

  const init = useCallback(async () => {
    setStatus(VWO_STATUS.INITIALIZING);
    const accountId = window.env.VWO_ACCOUNT_ID;
    const sdkKey = window.env.VWO_SDK_KEY;
    let settingsFile = null;
    try {
      settingsFile = await vwoSdk.getSettingsFile(accountId, sdkKey);
    } catch (error) {
      setStatus(VWO_STATUS.FAILED);
      // ad blockers are the main source for errors with `getSettingsFile`.
      // we don't want to log these errors to Sentry, as they are not actionable.
      // We'll return early here so we don't attempt to launch the VWO client.
      return;
    }

    try {
      const vwoClient = vwoSdk.launch({ settingsFile });
      setSavedVwoClient(vwoClient);
      setStatus(VWO_STATUS.INITIALIZED);
    } catch (error) {
      setStatus(VWO_STATUS.FAILED);
      captureException(new Error('VWO - Error launching VWO client'), {
        extras: {
          error: JSON.stringify(error),
          hasSettingsFile: Boolean(settingsFile),
          settingsFile: JSON.stringify(settingsFile),
        },
      });

      if (isLocalDev) {
        console.error('VWO - Error launching VWO client', error);
      }
    }
  }, [setSavedVwoClient]);

  const manuallyActivate = useCallback(() => {
    setEnableManualActivation(true);
  }, []);

  const activate = useCallback(async () => {
    // If we have a cookie override, use that and short circuit other checks
    let cookieVariant = Cookies.get('vwo_override');

    if (isNotProd && cookieVariant) {
      setStatus(VWO_STATUS.COMPLETED);
      setVariant(cookieVariant);
      return cookieVariant;
    }

    if (!vwoUuid) {
      captureException(new Error(`No vwoUuid found. Campaign: ${campaignKey}`));
    }

    if (!campaignKey || !vwoUuid) {
      setStatus(VWO_STATUS.COMPLETED);
      return null;
    }

    // If we've already activated this experiment, return the variant
    if (variant) {
      setStatus(VWO_STATUS.COMPLETED);
      return variant;
    }

    try {
      if (!savedVwoClient) {
        setStatus(VWO_STATUS.FAILED);
        captureException(
          new Error('VWO - No VWO client when activating experiment'),
          {
            extras: {
              experimentName: campaignKey,
            },
          }
        );
        return null;
      }

      setStatus(VWO_STATUS.FETCHING);
      const vwoVariant = await savedVwoClient.activate(campaignKey, vwoUuid);
      setVariant(vwoVariant);

      if (vwoVariant) {
        analyticsBeacon.emit(EventType.VWO_FULLSTACK_EXPERIMENT, {
          campaignKey,
          vwoVariant,
        });
      }

      setStatus(VWO_STATUS.COMPLETED);
      return vwoVariant;
    } catch (error) {
      setStatus(VWO_STATUS.FAILED);
      captureException(new Error('VWO - Error activating experiment'), {
        extras: {
          error: JSON.stringify(error),
          experimentName: campaignKey,
          vwoUuid,
        },
      });

      if (isLocalDev) {
        console.error(
          `VWO - Error activating experiment ${campaignKey}`,
          error
        );
      }
    }
  }, [campaignKey, savedVwoClient, variant, vwoUuid]);

  useEffect(() => {
    if (
      (activateOnLoad || enableManualActivation) &&
      campaignKey &&
      savedVwoClient &&
      vwoUuid
    ) {
      activate();
    } else {
      setStatus(VWO_STATUS.FAILED);
    }
  }, [
    activate,
    activateOnLoad,
    campaignKey,
    enableManualActivation,
    savedVwoClient,
    vwoUuid,
  ]);

  // When a user qualifies for the campaign, variation name is returned.
  const getVariantName = () => {
    // If we have a cookie variant, use that and short-circuit other checks
    let cookieVariant = Cookies.get('vwo_override');
    if (isNotProd && cookieVariant) {
      return cookieVariant;
    }

    if (!campaignKey || !vwoUuid || !savedVwoClient) {
      return;
    }

    return savedVwoClient.getVariationName(campaignKey, vwoUuid);
  };

  return {
    init,
    variant,
    activate: manuallyActivate,
    getVariantName,
    status,
  };
};
