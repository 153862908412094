import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import { shedCategoryRoute } from '~/routes';

import styles from '~/components/the-shed/components/article-block.module.scss';

const ArticleBlockCategory = ({ category, className }) => {
  return (
    <div className={styles.articleBlockCategoryContainer}>
      <Link
        aria-label={category?.articleCategory?.slug}
        className={cx(styles.articleBlockCategory, className)}
        to={shedCategoryRoute({
          category: category?.articleCategory?.slug,
        })}
      >
        {category?.articleCategory?.name}
      </Link>
      {category?.articleSubCategory?.slug && (
        <>
          <span className={styles.spacer}>&nbsp;/&nbsp;</span>
          <Link
            aria-label={category?.articleCategory?.slug}
            className={cx(styles.articleBlockCategory, className)}
            to={shedCategoryRoute({
              category: category?.articleCategory?.slug,
              subCategory: category?.articleSubCategory?.slug,
            })}
          >
            {category?.articleSubCategory?.name}
          </Link>
        </>
      )}
    </div>
  );
};

export { ArticleBlockCategory };
