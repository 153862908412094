import React from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

import styles from '~/components/the-shed/components/article-block.module.scss';

const ArticleBlockTitle = ({ title, to, className }) => (
  <Link to={to}>
    <h3 className={cx(styles.articleBlockTitle, className)}>{title}</h3>
  </Link>
);

export { ArticleBlockTitle };
