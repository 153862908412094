import 'vite/modulepreload-polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import queryString from 'query-string';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router';

import App from '~/components/app';
import ErrorBoundary from '~/components/error/error-boundary';
import { appQueryClient } from '~/utils/configure-query-client';
import { initExceptionTracking } from '~/utils/exception-tracking';
import { analyticsBeacon } from '~/utils/analytics';
import { initUTMTracking } from '~/utils/utm-tracking';

import '~/utils/request-idle-callback-shim';

import '~/styles/index.scss';

main();

async function main() {
  const queryParams = queryString.parse(window.location.search);

  observeCLS();
  initExceptionTracking();
  initPreloadErrorListener();
  initUTMTracking(queryParams);
  renderApp();
  analyticsBeacon.init();
}

function renderApp() {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <ErrorBoundary>
      <QueryClientProvider client={appQueryClient}>
        <BrowserRouter
          future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
          }}
        >
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

/**
 * Log cumulative layout shift (CLS) to the console
 * during development.
 * https://web.dev/debug-layout-shifts/
 */
function observeCLS() {
  if (window.env.DEPLOY_ENV !== 'production') {
    let cls = 0;
    new PerformanceObserver((entryList) => {
      for (const entry of entryList.getEntries()) {
        if (!entry.hadRecentInput) {
          cls += entry.value;
          console.warn(
            'CLS:',
            Math.round(cls * 10000) / 10000,
            'Shifting elements:',
            entry.sources.map((s) => s.node),
            entry
          );
        }
      }
    }).observe({ type: 'layout-shift', buffered: true });
  }
}

/**
 * Add a listener for preload errors to capture them
 * and eventually reload the page.
 *
 * https://vitejs.dev/guide/build#load-error-handling
 */
function initPreloadErrorListener() {
  // window.addEventListener('vite:preloadError', (event) => {
  //   captureException(new Error('vite:preloadError'), {
  //     extras: {
  //       eventPayload: event?.payload,
  //     },
  //   });
  //   // window.location.reload(); // Uncomment to reload the page on preload error
  // });
}
